import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mezzoforte+forge-icons@1.2.3_react-dom@18.2.0_react@18.2.0/node_modules/@mezzoforte/forge-icons/dist/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mezzoforte+forge@10.1.4_@emotion+react@11.13.0_@emotion+styled@11.13.0_@types+react@18.2.38__vqa5jjgx5apszdbro4lwug42om/node_modules/@mezzoforte/forge/dist/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prismicio+next@1.6.0_@prismicio+client@7.8.0_next@14.1.4_react@18.2.0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prismicio+next@1.6.0_@prismicio+client@7.8.0_next@14.1.4_react@18.2.0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prismicio+next@1.6.0_@prismicio+client@7.8.0_next@14.1.4_react@18.2.0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prismicio+react@2.8.0_@prismicio+client@7.8.0_react@18.2.0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/public/images/hero-background.svg");
import(/* webpackMode: "eager" */ "/app/slices/LinkBlocks/index.tsx");
import(/* webpackMode: "eager" */ "/app/slices/MainNavigationItem/NavItemDropdown.tsx");
import(/* webpackMode: "eager" */ "/app/slices/MainNavigationItem/NavItemUserMenu.tsx");
import(/* webpackMode: "eager" */ "/app/slices/RegistrationIconBlocks/index.tsx");
import(/* webpackMode: "eager" */ "/app/slices/RegistrationLinkBlocks/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Category/CategoryMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Container/Container.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/EntryList/EntryList.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Favorites/FavoritesList.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Header/HeroCountdown.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Link/LinkButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Notification/Notifications.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/EntryPage/SidebarSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/FrontPage/FrontPageAnalytics.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Precontent/Precontent.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/SiteHeader/MainNavigationItem.tsx")