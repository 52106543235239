import React, { useState, useEffect } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import { Flex, Text, useTokens } from '@mezzoforte/forge';

const { fontWeights, colors, radii } = useTokens.huutokaupat;

const MotionFlex = motion(Flex);

interface DigitCardProps {
  unit: number | string;
}

const DigitCard = ({ unit }: DigitCardProps) => {
  const [displayUnit, setDisplayUnit] = useState(unit);
  const controls = useAnimationControls();

  useEffect(() => {
    controls.start({
      transition: { duration: 1, ease: 'easeInOut' },
    });
    setDisplayUnit(unit);
  }, [unit, controls]);

  return (
    <MotionFlex
      data-test-id="digit-card"
      animate={controls}
      justifyContent="center"
      w="100%"
      h="100%"
      overflow="hidden"
      sx={{ backfaceVisibility: 'hidden', transformStyle: 'preserve-3d' }}
      alignItems="center"
      border={`2px solid ${colors.inputBorder}`}
      borderRadius={radii.box}
    >
      <Text
        fontSize={{ base: '1.5rem', sm: '2rem', xl: '2.2rem' }}
        fontFamily="inherit"
        fontWeight={fontWeights.h1}
        color={colors.textInvert}
      >
        {displayUnit}
      </Text>
    </MotionFlex>
  );
};

export default DigitCard;
