'use client';

import { useState } from 'react';
import { useClientRendering } from 'hooks/useClientRendering';
import useSendAnalytics from 'hooks/useSendAnalytics';

export function FrontPageAnalytics() {
  const [isSent, setIsSent] = useState(false);
  const sendAnalytics = useSendAnalytics();
  const isClient = useClientRendering();

  if (!isSent && isClient) {
    sendAnalytics('frontPageView');
    setIsSent(true);
  }

  return null;
}
