import React from 'react';
import { Box } from '@mezzoforte/forge';
import { EntryListItem } from './EntryListItem';

const DummyEntryCard = () => (
  <EntryListItem
    backgroundColor="backgroundGray"
    flexDirection={{ base: 'row', md: 'column' }}
    padding={{ base: '1rem', md: 0 }}
  >
    <Box height={{ base: 92, md: 163, lg: 213 }} width={{ base: 180, md: 'auto' }} backgroundColor="lineBorder" />
    <Box padding={{ base: '0 .8rem', md: 3 }} width={{ base: '60%', md: 'auto' }}>
      <Box backgroundColor="lineBorder" height="1.5rem" width="80%" marginBottom={{ base: 2, md: 2 }} />
      <Box backgroundColor="lineBorder" height="1rem" width="40%" marginBottom={{ base: 2, md: 0 }} />
    </Box>
  </EntryListItem>
);

export default DummyEntryCard;
