import { useMemo } from 'react';
import { Box, Text, useTokens } from '@mezzoforte/forge';
import { Center } from '@chakra-ui/react';

import DigitCard from './DigitCard';

const { colors, fontSizes, fontWeights } = useTokens.huutokaupat;

export type CountdownLabel = 'Päivää' | 'Tuntia' | 'Minuuttia' | 'Sekuntia';

interface DigitContainerProps {
  number: number;
  label: CountdownLabel;
  useLabels: boolean;
}

export const DigitContainer = ({ number, label, useLabels }: DigitContainerProps) => {
  const isMinutesOrSeconds = label === 'Minuuttia' || label === 'Sekuntia';

  const current = useMemo(() => {
    const currentValue = resolveValue(number, isMinutesOrSeconds);
    return currentValue;
  }, [number, isMinutesOrSeconds]);

  return (
    <Box>
      <Box
        display="block"
        position="relative"
        w={{ base: '64px', md: '80px' }}
        h={{ base: '48px', md: '64px' }}
        rounded={6}
      >
        <DigitCard unit={current} />
      </Box>
      {useLabels && (
        <Center py={2} backgroundColor="transparent">
          <Text
            fontSize={fontSizes.bodySmall}
            fontFamily="inherit"
            fontWeight={fontWeights.bodySmall}
            color={colors.textInvert}
          >
            {label}
          </Text>
        </Center>
      )}
    </Box>
  );
};

function resolveValue(val: number, isMinOrSec: boolean) {
  if (val < 10) return `0${val}`;
  return isMinOrSec && val === 60 ? '00' : val;
}
