import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { client } from 'util/apiClient';
import { FrontPageEntryAPIResponse } from 'types/ApiResponse';
import { ListEntry } from '../types/ListEntry';
import { apiToEntry } from '../util/entry';

const fetchFrontPageEntries = async (): Promise<ListEntry[]> =>
  client
    .get<FrontPageEntryAPIResponse[]>('/api/front-page/entries')
    .then(response => response.data)
    .then(data => data.map(entry => apiToEntry(entry)))
    .catch((error: Error | AxiosError) => Promise.reject(error));

export const useFrontPageEntries = () =>
  useQuery({ queryKey: ['entries', 'front-page'], queryFn: fetchFrontPageEntries, staleTime: 1000 * 60 * 5 });
